import React from "react";
import ProtocolEdit from "../../../Protocol/ProtocolEdit";
import { styled } from "styled-components";
import { useSelector } from "react-redux";

function ProtocolEditModal({ setIsProtocol, meetingId, agendas }) {
  const StyledProtocolEditModal = styled.div`
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <StyledProtocolEditModal>
      <ProtocolEdit
        setIsProtocol={setIsProtocol}
        meetingId={meetingId}
        agendas={agendas}
      />
    </StyledProtocolEditModal>
  );
}

export default ProtocolEditModal;
