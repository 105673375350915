import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Img, StyledBox } from "./style";
import { Link, useNavigate } from "react-router-dom";
// import Languages from "./components/Languages";
import routeNames from "../../constants/routeNames";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { EProtocol, HomeIcon, NavoiyUran } from "../../assets/icons";
import Language from "../Language";
import { Notification } from "../";
import { t } from "i18next";

function NewHeader() {
  const navigate = useNavigate();
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const userData = useSelector((store) => store.userData);
  const [menuToggle, setMenuToggle] = useState(false);
  const { lng } = useSelector((store) => store.language);

  const redirect =
    isLogged && user_role === "ROLE_ADMIN"
      ? routeNames.ADMIN
      : routeNames.USER_COMPANIES;

  const handleToggleMenu = () => {
    setMenuToggle(!menuToggle);
  };

  const onLogoutClick = () => {
    dispatch.auth.logoutAsync();
    navigate(routeNames.LOGIN);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "#fff" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 2,
              display: { xs: "none", md: "flex" },
              padding: "10px 0",
            }}
          >
            <Link to={redirect}>
              <Img src={EProtocol} alt="" />
            </Link>
            <Link to={redirect}>
              <Img src={NavoiyUran} alt="" />
            </Link>
          </Box>
          <Language />
          <Notification />
          <Button onClick={() => navigate(redirect)}>
            <img src={HomeIcon} alt="Notification" />
          </Button>
          <Box sx={{ flexGrow: 0 }}>
            {isLogged ? (
              <StyledBox position={"relative"}>
                <Button className="header-button" onClick={handleToggleMenu}>
                  <Typography variant="p">
                    {userData[`fullName_${lng}`]}
                  </Typography>
                  {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                </Button>

                {menuToggle && (
                  <Box position="absolute" className="menu-box">
                    <div
                      className="menu-item"
                      onClick={() => {
                        navigate(routeNames.PROFILE);
                        handleToggleMenu();
                      }}
                    >
                      <Typography variant="p" textAlign="center" color="black">
                        {t("userProfile")}
                      </Typography>
                    </div>
                    <div className="menu-item" onClick={onLogoutClick}>
                      <Typography variant="p" textAlign="center" color="black">
                        {t("logout")}
                      </Typography>
                    </div>
                  </Box>
                )}
              </StyledBox>
            ) : (
              <Link to={routeNames.LOGIN} style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    width: "150px",
                  }}
                >
                  Boshlamoq
                </Button>
              </Link>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NewHeader;
