import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";
import { Box } from "@mui/material";

export const StyledBox = styled(Box)`
  background: #208667;
  padding: 30px 0;

  .footer-top {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .footer-list {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;
  }

  h4 {
    color: var(--base-white, #fff);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.36px;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    overflow-x: hidden;

    .footer-list {
      width: 100%;
      align-items: center;
    }

    .footer_list_first {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      gap: 0px;

      .main-logo-img {
        width: 45%;

        img {
          width: 100%;
        }
      }
    }

    .footer_icon {
      max-width: 200px;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;

  .footer-link {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footer-links {
    width: 25px;
    height: 25px;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Img = styled.img`
  width: 200px;
`;

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FlexPage = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: fit-content;

    .footer-list {
      width: fit-content;

      &:first-child {
        padding: 0;
        margin: 0;
      }
    }

    ul {
      padding: 0;
    }
  }
`;
