import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";

export const MeetingQrCodeStyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  background: var(--bgImage);
  padding: ${(props) => props.padding};
  padding-bottom: 50px;

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  .page__list {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .page__list_item {
    font-size: 17px;
  }

  .page__list_item--span {
    font-weight: 700;
  }

  .item-simple {
    text-align: center;
  }

  a {
    margin-left: auto;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 22px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 8px;
    background: #208667;
    outline: none;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .user__table {
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    h2 {
      text-align: center;
    }

    .page__list {
      width: 90%;
      /* margin: 0 auto; */
    }

    a {
      margin: 0 auto;
    }
  }
`;
