import styled from "styled-components";
import { MIN_LENGTH } from "../../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  .pagination-bar {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .user-link {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top: 0;
  }
  .user-top {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 15px;
  }

  .user-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    width: 631px;
    height: 35px;
    padding: 5px;
  }

  input {
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;
  }

  input::placeholder {
    color: #9d9d9d;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .user-create {
    border-radius: 8px;
    background: #208667;
    display: inline-flex;
    padding: 12px 22px;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const UserTable = styled.table`
  width: 70%;
  margin: 0 auto;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  padding: 15px;
  caption-side: bottom;
  border-collapse: collapse;
  margin-top: 30px;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);

  @media (max-width: 768px) {
    width: 100%;
  }

  thead {
    background: #ddf8f0;
    overflow: auto;
  }

  th {
    color: #208667;
    /* padding: 15px 47px 15px 0px; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* padding-left: 30px; */
  }

  tr {
    height: 53px;
  }

  .list-item {
    border-bottom: 1px solid #ddf8f0;
    cursor: pointer;
  }

  td {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 0;
  }

  .user-delete {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .menu-box {
    background: #fff;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    padding: 8px 10px 7px 8px;
    justify-content: center;
    align-items: flex-start;
    margin-top: -30px;
    margin-left: 3px;
  }

  .companyName_card {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .companyName_card_icons {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .companyName_card_icons img {
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
`;
