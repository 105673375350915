import React from "react";
import { useQuery } from "react-query";
import { MeetingQrCodeStyle } from "./style";
import { UserTable } from "../Admin/Company/style";
import {
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../constants";
import download from "../../assets/icons/download.png";
import { useSelector } from "react-redux";
import { API } from "../../services/api";
import { useParams } from "react-router-dom";

const MeetingQrCode = () => {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const { isLogged } = useSelector((store) => store.auth);
  const { meetingId } = useParams();
  const { data, isLoading } = useQuery(
    ["MeetingQrCodeGet", meetingId],
    async () => {
      return await API.getMeetingQrCode(meetingId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => console.log(err));
    }
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  return (
    <MeetingQrCodeStyle padding={isLogged ? "50px 0" : "120px 0"}>
      <div className="container">
        <h2 className="page__title">{t("meetigQrCodePage.title")}</h2>

        <ul className="page__list">
          <li className="page__list_item">
            {t("meetigQrCodePage.protocolNumber")}:{" "}
            <span className="page__list_item--span">
              {data?.protocolNumber && data?.protocolNumber}
            </span>
          </li>
          <li className="page__list_item">
            {t("meetigQrCodePage.protocolDate")}:{" "}
            <span className="page__list_item--span">
              {data?.startDate && data?.startDate.slice(0, 10)}
            </span>
          </li>
          <li className="page__list_item">
            {t("meetigQrCodePage.protocolSecratar")}:{" "}
            <span className="page__list_item--span">
              {data?.chairman && data?.chairman.slice(0, 10)}
            </span>
          </li>
          <li className="page__list_item item-simple">
            {t("meetigQrCodePage.protocolSignInMember")}
          </li>
          <div className="user__table">
            <UserTable aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>T/R</TableCell>
                  <TableCell align="center">
                    {t("AdminUser.fullName")}
                  </TableCell>
                  <TableCell align="center">{t("AdminUser.email")}</TableCell>
                  <TableCell align="center">{t("AdminUser.number")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.users &&
                  data.users.map((user, idx) => (
                    <TableRow
                      className="list-item"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {idx + 1}
                      </TableCell>
                      <TableCell align="right">
                        {user[`fullName_${lng}`]}
                      </TableCell>
                      <TableCell align="right">{user.email}</TableCell>
                      <TableCell align="right">{user.phoneNumber}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </UserTable>
          </div>
          {data?.users.length === 0 ? (
            <p>{t("meetigQrCodePage.protocolSignInMemberNot")}</p>
          ) : null}
        </ul>

        <a
          href={BASE_URL + "/meeting/generate-pdf?meetingId=" + meetingId}
          target="_blank"
        >
          <span>{t("userPage.downloadProtocol")}</span>
          <img src={download} alt="download" />
        </a>
      </div>
    </MeetingQrCodeStyle>
  );
};

export default MeetingQrCode;
