import React, { useContext } from "react";
import {
  AgendaVoitingButtons,
  AgendaVoitingDiv,
  AgendaVoitingInnertDiv,
} from "./AgendaVoitingStyle";
import { ProtocolBottom } from "../../../ChairmanProtocol/style.js";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import SignWithERI from "../../../ChairmanProtocol/SignWithERI.js";
import ProgressBar from "../../../../../../components/ProgressbBar";
import MyButton from "../../../../../../components/UI/MyButton/MyButton";
import { AgendaContext } from "../../../../pages/MeetingDetails/context/MeetingDetailContext";
import { useMutation, useQuery } from "react-query";
import { API } from "../../../../../../services/api";
import sign from "../../../../../../assets/icons/sign.png";
import { useState } from "react";
import { BALLOT_OPTIONS, STATUSES } from "../../../../../../constants";
import { toast } from "react-toastify";
import { QUERIES_NAME } from "../../../../../../constants/apiqueries";
import { Box, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const createVotesBtnData = (t) => {
  return [
    {
      id: 1,
      label: t("userPage.votes.votePositive"),
      type: BALLOT_OPTIONS.FOR,
      color:
        "linear-gradient(270deg, rgba(221, 248, 240, 0.30) 0%, rgba(6, 94, 169, 0.30) 100%), linear-gradient(270deg, #3EB250 0%, rgba(62, 178, 80, 0.60) 100%), rgba(2, 119, 21, 0.00);",
    },
    {
      id: 2,
      label: t("userPage.votes.voteNeutral"),
      type: BALLOT_OPTIONS.REFRAIN,
      color:
        "linear-gradient(90deg, rgba(255, 0, 0, 0.24) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, #FFC700 0%, rgba(255, 199, 0, 0.40) 100%)",
    },
    {
      id: 3,
      label: t("userPage.votes.voteControversy"),
      type: BALLOT_OPTIONS.AGAINST,
      color:
        "linear-gradient(90deg, rgba(255, 199, 0, 0.21) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, rgba(255, 0, 0, 0.90) 0%, rgba(255, 0, 0, 0.60) 100%)",
    },
  ];
};

const AgendaVoiting = ({
  memberRole,
  socketMeetingData,
  meetingInfo,
  meetVoiceData,
}) => {
  const [votes, setVotes] = useState([]);
  const [open, setOpen] = useState(false);
  const { activeAgenda, setIsVotes } = useContext(AgendaContext);
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const meetVoice = Array.isArray(meetVoiceData) ? meetVoiceData : [];
  const [meetingId, setMeetingId] = useState();

  const { mutate: agendaCreateBallot, isLoading: agendaCreateBallotLoading } =
    useMutation(async (payload) => {
      return await API.createBallot(payload)
        .then((res) => {
          toast.success("successful");
          setIsVotes(true);
        })
        .catch((err) => {
          toast.error(err.message);
          if (
            err.response.data.title ===
            "Ballot from MeetingID, MemberID, AgendaID and VariantID has already in database"
          ) {
            toast.error(t("userPage.votes.alreadyVotes"));
          } else {
            toast.error(t("userPage.votes.repeatVotes"));
          }
        });
    });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { isFetching, refetch: ballotAgendaMemberRefetch } = useQuery(
    [QUERIES_NAME.GET_BALLOT_AGENDA_MEMBER_BY_ID, activeAgenda.id],
    async () => {
      const data = {
        agendaId: activeAgenda.id,
        memberId: memberRole.id,
      };
      const response = await API.getBallotAgendaMemberById(data)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].memberId === memberRole.id) {
              setIsVotes(true);
            }
          } else {
            setIsVotes(false);
          }
          return res.data;
        })
        .catch((err) => console.log(err));
      return response;
    }
  );

  const handleClick = () => {
    if (!votes.length || votes.some((vote) => vote === undefined)) {
      toast.warning(t("userPage.votes.chooseVotes"));
    } else {
      if (meetingInfo?.status?.toUpperCase() === STATUSES.PENDING) {
        toast.error(`Meeting status ${STATUSES.PENDING}`);
      } else {
        console.log("ballots");
        const ballots = meetVoice.map((item, idx) => {
          const { votingOptions, meetingId, id } = item;
          return {
            agendaId: id,
            meetingId: meetingId,
            memberId: memberRole.id,
            options: votes[idx],
            votingOptionId: votingOptions[0].id,
          };
        });
        agendaCreateBallot(ballots);
      }
    }
  };

  useEffect(() => {
    ballotAgendaMemberRefetch();
  }, [activeAgenda]);

  const handleVoteSelection = (cardIdx, voteType) => {
    setVotes((prevVotes) => {
      const updatedVotes = [...prevVotes];
      updatedVotes[cardIdx] = voteType;
      return updatedVotes;
    });
  };

  return (
    <AgendaVoitingDiv>
      {isFetching ? (
        <Box
          style={{ display: "flex", justifyContent: "center" }}
          className="loading-box"
        >
          <CircularProgress
            color="success"
            style={{ width: "100px", height: "100px" }}
          />
        </Box>
      ) : (
        <>
          <>
            {meetVoice.map((item, idx) => (
              <div className="card_voice" key={idx}>
                <h2>{item[`subject_${lng}`]}</h2>
                <AgendaVoitingInnertDiv>
                  <AgendaVoitingButtons>
                    {createVotesBtnData(t).map((el) => (
                      <ProgressBar
                        key={el.id}
                        percent="100%"
                        color={el.color}
                        center="center"
                        fontColor="#FFFFFF"
                        width="457px"
                        onClick={() => handleVoteSelection(idx, el.type)}
                        disabled={votes[idx] === el.type}
                      >
                        <span>{el.label}</span>
                      </ProgressBar>
                    ))}
                  </AgendaVoitingButtons>
                </AgendaVoitingInnertDiv>
              </div>
            ))}
            <div className="agenda-voiting-footer">
              {agendaCreateBallotLoading ? (
                <MyButton>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                    }}
                  />
                </MyButton>
              ) : (
                <div>
                  <MyButton
                    padding="8px 16px"
                    color="#FAFAFA"
                    background="#208667"
                    backgroundHover="rgba(32, 134, 103, 0.8)"
                    onClick={handleClick}
                  >
                    {t("userPage.votes.createVote")}
                  </MyButton>

                  {/* <ProtocolBottom>
                        <button onClick={handleClickOpen}>
                          <span>{t("userPage.protocol.protocolSgin")}</span>
                          <img src={sign} alt="sign" />
                        </button>
                      </ProtocolBottom> */}
                  {/* <Dialog open={open} onClose={handleClose}>
                        <DialogContent>
                          <SignWithERI
                            meetingId={meetingInfo?.id}  // use meetingInfo to get the meetingId
                            userId={memberRole?.id}      // use memberRole for user details
                            login={memberRole?.login}    // if login is part of memberRole, use it
                            handleClose={handleClose}    // handleClose to close the dialog
                          />
                        </DialogContent>
                        <DialogActions>
                          <MyButton onClick={handleClose}>
                            {t("userPage.protocol.close")}
                          </MyButton>
                        </DialogActions>
                      </Dialog> */}
                </div>
              )}
            </div>
          </>
        </>
      )}
    </AgendaVoitingDiv>
  );
};

export default AgendaVoiting;
