import React, { useEffect, useState } from "react";
import AgendaForm from "../AgendaForm/AgendaForm";
import styled from "styled-components";
import { useMutation } from "react-query";
import { API } from "../../../../../../../../services/api";
import { MyButton } from "../../../../../../../../components";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../../../../../utils";

const initialState = {
  subject_uz: "",
  subject_ru: "",
  subject_en: "",
  speakerId: "",
  debateEnum: "",
  speakTimeEnum: "",
  issueInfo_uz: "",
  issueInfo_ru: "",
  issueInfo_en: "",
  editor_uz: "",
  editor_ru: "",
  editor_en: "",
};

const getAgendaByIdFunc = async ({
  id,
  setData,
  setAgendaIdLoading,
  t,
  setAnswers,
  setEditIssueFile
}) => {
  try {
    setAgendaIdLoading(true);
    return await API.getAgendaById(id).then((res) => {
      API.getAgendaAnswers({meetingId: res.data.meetingId, agendaId: id}).then((res) => {
        console.log("answers => ", res.data);
        setAnswers(
          res.data.answers[0]
            ? res.data.answers[0]
            : {
                editor_uz: "",
                editor_en: "",
                editor_ru: "",
              }
        );
      });

      API.getAllFileByAgendaId(id)
        .then((res) => {
          setEditIssueFile(res.data);
        })
      setData((prev) => ({
        ...prev,
        ...res.data,
        issueInfo_uz: res.data.votingOptions[0].votingText_uz,
        issueInfo_ru: res.data.votingOptions[0].votingText_ru,
        issueInfo_en: res.data.votingOptions[0].votingText_en,
      }));
      return res.data;
    });
  } catch (e) {
    console.log(t("notAgenda") + ": ", e);
  } finally {
    setAgendaIdLoading(false);
  }
};


const answer = (agendaId, editorData_uz, editorData_ru, editorData_en) => {
  let result = [];
  result.push({
    agendaId: agendaId,
    answer_uz: editorData_uz,
    answer_ru: editorData_ru,
    answer_en: editorData_en,
  });
  return result;
};

const AgendaEditModal = ({ speakers, id, setOpen, open, getAgendaRefetch }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(initialState);
  const [agendaIdLoading, setAgendaIdLoading] = useState(true);
  const [issueFile, setIssueFile] = useState([]);
  const [editIssueFile, setEditIssueFile] = useState([]);

  const [answers, setAnswers] = useState([]);

  const updateEditors = (answers) => {
    setData((prevData) => ({
      ...prevData,
      editor_uz: answers[0]?.answer_uz,
      editor_ru: answers[0]?.answer_ru,
      editor_en: answers[0]?.answer_en,
    }));
  };

  const deleteAgendaFileById = async(id) =>{
    const res = API.deleteAgendaFile(id)
      .then((res) => {
        toast.success("success");
        setEditIssueFile((prevFiles) => prevFiles.filter((file) => file.id !== id));
      })
      .catch((err) => {
        toast.error(err.message);
      })
  }

  const {
    mutate,
    isLoading: agendaEditLoading,
    isSuccess,
    isLoading: agendaFileLoading
  } = useMutation(async ({ data, setData }) => {
    return await API.updateAgendaData(data)
      .then((res) => {
        console.log("data => ", data);

        const meetingId = res.data.meetingId;
        let answers = answer(res.data.id, data.editor_uz, data.editor_ru, data.editor_en);

        console.log("answers => ", answers);
        console.log("meetingId => ", meetingId);  

        API.editProtocol({answers: answers, meetingId})
          .then((res) => {
            console.log("res => ", res);
          })
        
        console.log("issueFile => ", issueFile);
        if (Array.isArray(issueFile) && issueFile.length > 0) {
          issueFile.forEach((file) => {
            const fileData = new FormData();
        
            fileData.append("files", file);
            fileData.append("agendaId", res.data.id);
            fileData.append("language", "uz");
        
            mutate({ fileData, agendaId: res.data.id });
          });
        } else {
          console.log("issueFile is not an array or is empty");
        }
          

        if (!issueFile?.files) {
          setOpen(false);
        }

        setData({ ...initialState });
        toast.success(t("dataUpdated"));
        getAgendaRefetch();
      })
      .catch((err) => {
        toast.error(`${t("errorOccurred")} ${err}`);
        console.log(t("agendaNotUpdated"), err);
      });
  });

  const meetingId = JSON.parse(localStorage.getItem("newMeetnigId")).id;

  const handleSubmit = (e) => {
    console.log("handle submit => ", data)
    const newAgenda = {
      active: true,
      debateEnum: data.debateEnum,
      meetingId,
      speakTimeEnum: data.speakTimeEnum,
      speakerId: data.speakerId,
      speakerStatus: true,
      subject_uz: data.subject_uz,
      subject_en: data.subject_ru,
      subject_ru: data.subject_en,
      typeEnum: "FULL",
      id: data.id,
      editor_uz: data.editor_uz,
      editor_ru: data.editor_ru,
      editor_en: data.editor_en,
      isActive: true,
      answerOptions: data.answerOptions,
      votingOptions: [
        {
          ...data.votingOptions[0],
          votingText_uz: data.issueInfo_uz,
          votingText_ru: data.issueInfo_ru,
          votingText_en: data.issueInfo_uz,
        },
      ],
    };

    e.preventDefault();

    mutate({
      data: newAgenda,
      setData,
    });
  };

  const handleClose = (evt) => {
    if (evt.target.matches(".agenda-edit-box")) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getAgendaByIdFunc({ id, setData, setAgendaIdLoading, t, setAnswers, setEditIssueFile});
  }, [id]);

  useEffect(() => {
    updateEditors(answers);
  }, []);

  return (
    <div>
      <AgendaEditBox
        active={open}
        onClick={handleClose}
        className="agenda-edit-box"
      >
        <AgendaEditBoxInner>
          {agendaIdLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={"400px"}
              width={"400px"}
            >
              <CircularProgress
                color="success"
                style={{ width: "100px", height: "100px" }}
              />
            </Box>
          ) : (
            <>
              <AgendaForm
                data={data}
                setData={setData}
                speakers={speakers}
                setIssueFile={setIssueFile}
                issueFile={issueFile}
                isSuccess={isSuccess}
                answers={answers}
                editIssueFile={editIssueFile}
                setEditIssueFile={setEditIssueFile}
                deleteAgendaFile={deleteAgendaFileById}
              />
              <div className="form-box-footer">
                <MyButton
                  color="#208667"
                  background="#FFFFFF"
                  border="#208667"
                  onClick={() => setOpen(false)}
                >
                  {t("back")}
                </MyButton>

                {agendaEditLoading || agendaFileLoading ? (
                  <MyButton color="#FFFFFF" border="#208667">
                    <CircularProgress
                      color="success"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </MyButton>
                ) : (
                  <MyButton
                    color="#FFFFFF"
                    background="#208667"
                    border="#208667"
                    type="submit"
                    form="agendaForm"
                    onClick={handleSubmit}
                  >
                    {t("user.sozdat")}
                  </MyButton>
                )}
              </div>
            </>
          )}
        </AgendaEditBoxInner>
      </AgendaEditBox>
    </div>
  );
};

export default AgendaEditModal;

const AgendaEditBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: ${(props) => (props.active ? "block" : "none")};
`;

const AgendaEditBoxInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 526px;
  text-align: left;
  overflow-y: auto;
  background-color: #f6fcff;

  .form-box-footer {
    padding: 0 20px;
    padding-bottom: 20px;
  }
`;
