import { Box, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { GeneralStyle } from "./style";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import {
  meetingCreateFunc,
  meetingGetByIDFunc,
  meetingGetCities,
  meetingGetCompaniesFunc,
  updateMeetingFunc,
} from "./queryColbackFun";
import { MyButton } from "../../../../../../components";
import { meetingType, PENDING } from "../../../../../../constants";
import { editInputText } from "../../../../../../utils";
import { useSelector } from "react-redux";

const changeValue = (setValue, data) => {
  setValue("companyId", data.companyId);
  setValue("meetingType", data.meetingType);
  setValue("cityId", data.cityId);
  setValue("protocolNumber", data.protocolNumber);
  setValue("address_uz", data.address_uz);
  setValue("address_ru", data.address_ru);
  setValue("address_en", data.address_en);
  setValue("introduction_uz", data.introduction_uz);
  setValue("introduction_ru", data.introduction_ru);
  setValue("introduction_en", data.introduction_en);
  setValue("status", data.status);
  setValue("typeEnum", data.typeEnum);
  setValue("extraInfo", data.extraInfo ? data.extraInfo : "");
  setValue("startDate", data.startDate.slice(0, data.startDate.length - 1));
};

function MeetingGeneral({ setActive, setMeeting }) {
  const { t } = useTranslation();
  const { lng } = useSelector((store) => store.language);
  const navigation = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm();
  const meetingId = !!localStorage.getItem("newMeetnigId")
    ? JSON.parse(localStorage.getItem("newMeetnigId"))
    : null;

  const { data: companies } = useQuery(
    "getAllCompanies",
    meetingGetCompaniesFunc
  );

  const meetingTypes = [
    { value: meetingType.OFFLINE, label: t("offline") },
    { value: meetingType.ONLINE, label: t("online") },
  ];

  const { data: cities } = useQuery("cities", meetingGetCities);
  const { isLoading: meetingisLoading } = useQuery(
    "meetingData",
    async () => await meetingGetByIDFunc(meetingId),
    {
      enabled: !!meetingId,
      onSuccess: (data) => {
        if (!!data) {
          changeValue(setValue, data);
          localStorage.setItem("newMeetnigId", JSON.stringify(data));
        }
      },
    }
  );

  const { isLoading, mutate } = useMutation((props) =>
    meetingCreateFunc({ props, setActive, navigation, setMeeting, t })
  );

  const { mutate: updateMeeting, isLoading: updateMeetingLoading } =
    useMutation((props) =>
      updateMeetingFunc({ data: props, setActive, navigation, setMeeting, t })
    );

  const onSubmit = (data) => {
    const city = cities?.find((city) => city?.id === data?.cityId);
    const company = companies?.find(
      (company) => company?.id === data?.companyId
    )?.name_uz;

    const modifiedData = {
      ...data,
      address_uz: editInputText(data.address_uz),
      address_ru: editInputText(data.address_ru),
      address_en: editInputText(data.address_en),
      city: city,
      companyName: company,
      protocolNumber: data?.protocolNumber,
      status: PENDING,
      meetingType: data.meetingType,
    };

    if (!!meetingId) {
      updateMeeting({
        ...modifiedData,
        startDate: `${data.startDate}Z`, // Assuming you need to append seconds here
        id: meetingId.id,
      });
    } else {
      mutate({
        ...modifiedData,
        startDate: `${data.startDate}:00.000Z`, // Assuming you need to append seconds here
      });
    }
  };

  if (meetingisLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  return (
    <GeneralStyle>
      <form onSubmit={handleSubmit(onSubmit)} className="general-list">
        <Box className="general-item">
          <label>
            {t("meetingOrgans")} *
            <Select
              {...register("companyId", { required: true })}
              className="create-select"
              value={watch("companyId") ? watch("companyId") : ""}
              required
            >
              {companies?.map((status) => (
                <MenuItem key={status?.id} value={status?.id}>
                  {status[`name_${lng}`]}
                </MenuItem>
              ))}
            </Select>
          </label>

          <label>
            {t("meetingsList.typeMeeting") + " *"}
            <Select
              {...register("meetingType", { required: true })}
              className="create-select"
              value={watch("meetingType") ? watch("meetingType") : ""}
              required
            >
              {meetingTypes?.map((type) => (
                <MenuItem key={type?.value} value={type?.value}>
                  {type?.label}
                </MenuItem>
              ))}
            </Select>
          </label>
        </Box>

        <Box className="general-item">
          <label>
            {t("idProtocol")} *
            <input
              {...register("protocolNumber", { required: true })}
              type="number"
              required
              placeholder={t("companyForm.enter")}
            />
          </label>

          <label>
            {t("cityOrRegion")}
            {watch("meetingType") === meetingType.OFFLINE && " *"}
            <Select
              {...register("cityId")}
              className="create-select"
              value={watch("cityId") ? watch("cityId") : ""}
              required={watch("meetingType") === meetingType.OFFLINE}
            >
              {cities?.map((cities) => (
                <MenuItem key={cities?.id} value={cities?.id}>
                  {cities.nameUz}
                </MenuItem>
              ))}
            </Select>
          </label>
        </Box>

        <Box className="general-item general-items">
          <Box>
            <label>
              {t("meetingsList.address") + " uz"}
              {watch("meetingType") === meetingType.OFFLINE && " *"}
              <input
                {...register("address_uz")}
                type="text"
                placeholder={t("companyForm.enter")}
                required={watch("meetingType") === meetingType.OFFLINE}
              />
            </label>

            <label>
              {t("meetingsList.address") + " ru"}
              {watch("meetingType") === meetingType.OFFLINE && " *"}
              <input
                {...register("address_ru")}
                type="text"
                placeholder={t("companyForm.enter")}
                required={watch("meetingType") === meetingType.OFFLINE}
              />
            </label>

            <label>
              {t("meetingsList.address") + " en"}
              {watch("meetingType") === meetingType.OFFLINE && " *"}
              <input
                {...register("address_en")}
                type="text"
                placeholder={t("companyForm.enter")}
                required={watch("meetingType") === meetingType.OFFLINE}
              />
            </label>

            <label>
              {t("introduction") + " uz"}
              <input
                {...register("introduction_uz")}
                type="text"
                placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("introduction") + " ru"}
              <input
                {...register("introduction_ru")}
                type="text"
                placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("introduction") + " en"}
              <input
                {...register("introduction_en")}
                type="text"
                placeholder={t("companyForm.enter")}
              />
            </label>

            <label>
              {t("beginningTime") + " *"}
              <input
                {...register("startDate", {
                  required: true, // Always required
                })}
                type="datetime-local"
                required
              />
            </label>
          </Box>
        </Box>
        {isLoading | updateMeetingLoading ? (
          <MyButton>
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
              }}
            />
          </MyButton>
        ) : (
          <button type="submit" className="general-submit">
            {t("next")}
          </button>
        )}
      </form>
    </GeneralStyle>
  );
}
export default MeetingGeneral;
