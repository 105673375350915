import React, { useEffect, useState } from "react";
import { API } from "../../../../../services/api";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";

import { BALLOT_OPTIONS, ROLES } from "../../../../../constants";
import img2 from "../../../../../assets/images/kotib/result.png";

import { QUERIES_NAME } from "../../../../../constants/apiqueries";
import ProgressBar from "../../../../../components/ProgressbBar";
import {
  InnerRightDiv,
  RightButtons,
  StatisticDiv,
  VotesDiv,
} from "../../Votes/style";
import { t } from "i18next";
import { MyButton } from "../../../../../components";
import { downloadIcon } from "../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../../constants";

function Vote({ subject, voit, index, agenda }) {
  const [voitFor, setVoitFor] = useState(0);
  const [voitRef, setVoitRef] = useState(0);
  const [voitIgn, setVoitIgn] = useState(0);
  const memberRole = JSON.parse(localStorage.getItem("memberRole"));



    const sum = voitFor + voitIgn + voitRef;
    const voitForPros = isNaN((voitFor / sum) * 100)
      ? 0
      : ((voitFor / sum) * 100).toFixed();
    const voitIgnPros = isNaN((voitIgn / sum) * 100)
      ? 0
      : ((voitIgn / sum) * 100).toFixed();
    const voitRefPros = isNaN((voitRef / sum) * 100)
      ? 0
      : ((voitRef / sum) * 100).toFixed();

    return (
      <VotesDiv>
        <div>
          <img src={img2} alt="img" />
        </div>
        <InnerRightDiv>
          <h3>{t("userPage.meetingManagement.votesResult")}</h3>

          <RightButtons>

            <a
                href={
                  BASE_URL +
                  "/pdf/ballot?meetingId=" +
                  5052 + "&language=eng-uz"
                  // "&lang="+selectedLngs.reduce((acc, item) => acc + "-"+item )
                }
                target="_blank"
              >
                <span>
                {memberRole?.memberTypeEnum === ROLES.SECRETARY
                  ? t("userPage.votes.downloadAllVotes")
                  : t("userPage.votes.downloadVotes")}
                </span>
              </a>

          </RightButtons>
        </InnerRightDiv>
      </VotesDiv>
    );
  }

function Votes({ agenda }) {
  const { lng } = useSelector((store) => store.language);

  const { meetingId } = useParams();
  const [agendas, setAgendas] = useState([]);

  useEffect(() => {
    const getAgendaByMeeting = async () => {
      try {
        const res = await API.getAgendaByMeetingID(meetingId);
        setAgendas(res.data);
      } catch (err) {
        console.error("Error fetching agendas:", err);
      }
    };

    if (meetingId) {
      getAgendaByMeeting();
    }
  }, [meetingId]);

  return (
    <>
      <Vote />
    </>
  );
}

export default Votes;
